<template>
  <div class="main">
    <van-nav-bar title="兑换记录" left-arrow @click-left="handleBack" fixed />

    <div style="margin-top: 44px">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
          <div v-for="item in list" :key="item.id" class="item">
            <!-- <div>
              <van-image
                  fit="cover"
                  class="item-img"
                  :src="require('../../static/images/v3/res.png')"
                />
            </div> -->
            <div class="item-info">
              <div class="item-info-title">
                订单号：{{ item.reqOrderId || "-"}}
              </div>
              <div class="item-info-tip">
                所属商家：{{ item.storeName || "-" }}
              </div>
              <div class="item-info-tip">
                兑换积分：{{ item.totalPoints || "-" }}
              </div>
              <div class="item-info-tip">
                交易状态：{{ item.orderStatus_dictText || "-" }}
              </div>
              <div class="item-info-date">
                交易时间：{{ item.transTime || "-" }}
              </div>
            </div>

            <!-- <div class="item-pay-points">
              <div>
                -{{ item.exchangeValue }}
                <span class="item-pay-points-symbol">磁石</span>
              </div>
            </div> -->

            <!-- <div class="btn-container">
              <div class="btn-container-btn" @click="handleDetail(item)">
                发货详情
              </div>
            </div> -->
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Row, Col, NavBar, Image, PullRefresh, List, Cell } from "vant";
import { getOrderListPage } from "@/api/global";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
  },

  data() {
    return {
      list: [],
      pageNo: 1,
      pageSize: 5,
      loading: false,
      finished: false,
      refreshing: false,

      storeId: null,
    };
  },
  created() {
    if (/micromessenger/.test(window.navigator.userAgent.toLowerCase())) {
      this.channel = "使用微信";
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
      this.channel = "使用支付宝";
    } else {
      this.channel = "";
    }

    if (this.$route.query.storeId) {
      this.storeId = this.$route.query.storeId;
    }
  },
  methods: {
    onLoad() {
      if (this.refreshing) {
        return;
      }

      let data = {};
      if (this.$route.query.channelCode) {
        data.channelCode = this.$route.query.channelCode;
      }

      if (this.$route.query.id) {
        data.transCode = this.$route.query.id;
      }

      if (this.$route.query.ddback) {
        data.ddback = this.$route.query.ddback;
      }

      if (this.$route.query.merId) {
        data.merId = this.$route.query.merId;
      }

      if (this.storeId) {
        data.storeId = this.storeId;
      } else if (this.$route.query.storeId) {
        data.storeId = this.$route.query.storeId;
      }

      if (this.$route.query.transCode) {
        data.transCode = this.$route.query.transCode;
      }

      data.pageNo = this.pageNo;
      data.pageSize = this.pageSize;

      console.log("data >>> ", data);
      // getSendDeliverByPage
      getOrderListPage(data).then((resp) => {
        console.log("getOrderListPage >>> ", resp);

        if (this.list.length == resp.total) {
          this.finished = true;
        } else {
          if (resp.records.length > 0) {
            if (this.pageNo == 1) {
              this.list = resp.records;
            } else {
              this.list.push(...resp.records);
            }

            this.pageNo++;
          } else {
            if (resp.records.length == 0) {
              if (this.pageNo == 1) {
                this.orderList = [];
              }
            }

            this.finished = true;
          }
        }

        this.loading = false;
      });
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     const d = this.list.length + 1
      //     this.list.push(d);

      //     this.$nextTick(() => {
      //       this.createQrcode('qrcode-' + d, `12312323-123-${d}`)
      //     })
      //   }
      //   this.loading = false;

      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    handleDetail(item) {
      let query = this.$route.query;

      if (this.$route.query.channelCode) {
        query.channelCode = this.$route.query.channelCode;
      }

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }

      if (this.$route.query.merId) {
        query.merId = this.$route.query.merId;
      }

      if (this.$route.query.storeId) {
        query.storeId = this.$route.query.storeId;
      }

      if (this.$route.query.ddback) {
        query.ddback = this.$route.query.ddback;
      }

      query.reqOrderId = item.reqOrderId;

      this.$router.push({
        path: "/detail",
        query,
      });
    },
    handleBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  min-height: 100vh;
  background-color: #f8f8f8;
}

:deep(.van-nav-bar__content) {
  background-color: #006bd9;
}

:deep(.van-nav-bar__title) {
  color: #fff !important;
}

:deep(.van-icon) {
  color: #fff !important;
}

.item {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 20px;
  margin: 10px 10px 0;
  border-radius: 5px;
  position: relative;

  &-img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
  }

  &-info {
    padding-left: 0px;
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 16px;
      padding-bottom: 8px;
    }

    &-tip {
      padding-top: px;
      font-size: 15px;
      color: #666;
    }

    &-date {
      padding-top: 6px;
      font-size: 14px;
      color: #999;
    }
  }

  &-pay-points {
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #006bd9;
  }
}

.btn-container {
  position: absolute;
  right: 10px;
  bottom: 10px;

  &-btn {
    font-size: 10px;
    border: 0.5px solid #ddd;
    padding: 5px 10px;
    border-radius: 50px;
  }
}
</style>
